import "./App.css";
import MessageList from "./components/MessageList";
import MessageSender from "./components/MessageSender";

function App() {
  return (
    <div className="App">
      <h2 className="chat-title">Chat App // Michael Ashe 2023</h2>
      <MessageList />
      <MessageSender />
    </div>
  );
}

export default App;
