import {
  collection,
  onSnapshot,
  orderBy,
  query,
  limit,
} from "firebase/firestore";

import { db } from "../firebase/config";

import { useState, useEffect } from "react";

import moment from "moment";

export default function MessageList() {
  const [data, setData] = useState(null);

  useEffect(() => {
    //   const unsub = onSnapshot(getDocs(collection(db, "messages")), (doc) => {
    //     const messageData = [];
    //     messageData.push({ ...doc.data(), id: doc.id });
    //     setData(messageData);
    //   });

    const q = query(
      collection(db, "messages"),
      orderBy("time", "desc"),
      limit(15)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messages = [];
      querySnapshot.forEach((doc) => {
        messages.push({ ...doc.data(), id: doc.id });
      });

      setData(messages);
    });
    return () => {
      unsubscribe();
    };

    //   const querySnapshot = await getDocs(collection(db, "messages"));

    //   const messageData = [];
    //   querySnapshot.forEach((doc) => {
    //     messageData.push({ ...doc.data(), id: doc.id });
    //   });

    //   setData(messageData);
  }, []);

  return (
    <div className="padding-added-list">
      <div className="message-list">
        {data &&
          data.map((message) => (
            <div key={message.id} className="message-box">
              <h3 className="message-header">{message.name}</h3>
              <hr />
              <p className="message-text">{message.message}</p>
              <p className="message-date">
                {moment(message.time).format("MM/DD/YY, hh:mm a")}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
}
