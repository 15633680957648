import { useState } from "react";

import { collection, addDoc } from "firebase/firestore";

import { db } from "../firebase/config";

export default function MessageSender() {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (message === "") {
        return;
      }

      if (name === "") {
        const docRef = await addDoc(collection(db, "messages"), {
          name: "Anonymous",
          message: message,
          time: Date.now(),
        });

        setMessage("");
        return;
      }
      const docRef = await addDoc(collection(db, "messages"), {
        name: name,
        message: message,
        time: Date.now(),
      });

      setMessage("");

      console.log("Document written with ID: ", docRef.id);
    } catch (err) {
      alert("Error adding message: ", err.message);
    }

    setMessage("");
  };

  return (
    <div className="padding-added-form">
      <form onSubmit={handleSubmit} className="message-form">
        <input
          type="text"
          placeholder="Name"
          value={name}
          className="name-input"
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Enter a message here..."
          value={message}
          className="message-input"
          onChange={(e) => setMessage(e.target.value)}
        />
        <input type="submit" value="Send Message" className="message-send" />
      </form>
    </div>
  );
}
