// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAhnilRCpk_WKQAeftWEOsPTr_lnrjjEEs",
  authDomain: "chatthing-412d9.firebaseapp.com",
  projectId: "chatthing-412d9",
  storageBucket: "chatthing-412d9.appspot.com",
  messagingSenderId: "263384044410",
  appId: "1:263384044410:web:a84740c4f63c4ca1d27cc3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore
const db = getFirestore(app);

// connectFirestoreEmulator(db, "127.0.0.1", 8080);

export { db };
